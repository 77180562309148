import { IconButton } from "@mui/material";
import React from "react";
import "./Settings.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import profile from "../../../../assets/logo/ProfileImg.svg";
import { BellZ, List, Question, UserCircleGear } from "phosphor-react";

const Settings = ({ setRender }) => {
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "22px" }}>Settings</h3>
        </div>
      </div>
      <div className="settings_profile">
        <img src={profile} alt="" />
        <h2>Jhon Doe</h2>
      </div>
      <div className="settings_details">
        <hr />
        <div className="settings_div" onClick={()=>setRender('account')}>
          <div className="settings_icon">
            <UserCircleGear color="white" size={22} weight="light" />
          </div>
          <h5>Accounts</h5>
        </div>
        <hr />
        
        <div className="settings_div" onClick={()=>setRender('notification')}>
          <div className="settings_icon">
            <BellZ color="white" size={22} weight="light" />
          </div>
          <h5>Notification</h5>
        </div>
        <hr />
        
        <div className="settings_div">
          <div className="settings_icon">
            <List color="white" size={22} weight="bold" />
          </div>
          <h5>Storage Data</h5>
        </div>
        <hr />
        <div className="settings_div" onClick={()=>setRender('help')}>
          <div className="settings_icon">
            <Question color="white" size={22} weight="light" />
          </div>
          <h5>Help</h5>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Settings;
