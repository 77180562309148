import React, { useEffect, useState, useCallback } from "react";
import Chats from "./ChatRoom/Chats";
import GroupChat from "./ChatRoom/GroupChat";
import NoChat from "./ChatRoom/NoChat";
import "./MainChat.css";
import AddNewPerticipaints from "./SideBar/AddNewPerticipaints";
import EditProfile from "./SideBar/Profile/EditProfile";
import Profile from "./SideBar/Profile/Profile";
import Accounts from "./SideBar/Settings/Accounts";
import Help from "./SideBar/Settings/Help";
import NewChat from "./SideBar/Settings/NewChat";
import Notification from "./SideBar/Settings/Notification";
import Settings from "./SideBar/Settings/Settings";
import SideBar from "./SideBar/SideBar";
import active from "../../assets/logo/active.svg";
import active2 from "../../assets/logo/active2.svg";
import active3 from "../../assets/logo/activeFe.svg";
import group from "../../assets/logo/group.svg";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import FriendProfile from "./SideBar/Profile/FriendProfile";
import io from "socket.io-client";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { showToast } from "../../utils/ToastHelper";

const ChatMainContainer = () => {
  const [chatId, setChatId] = useState(true);
  const [lastMessege, setLastMessege] = useState([]);
  const [index, setIndex] = useState(1);
  const token = localStorage.getItem("access_token");
  const [socket, setSocket] = useState(
    `wss://backend.snowtex.org/ws/chat/${token}/`
  );
  // const [socket, setSocket] = useState(
  //   `wss://echat-backend.devsstream.com/ws/chat/${token}/`
  // );
  useWebSocket(socket, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
  });
  useWebSocket(socket, {
    onClose: () => {
      console.log("WebSocket connection Disconected.");
    },
  });

  useEffect(() => {
    // const newSocket = new WebSocket(
    //   `wss://echat-backend.devsstream.com/ws/chat/${token}/`
    // );
    const newSocket = new WebSocket(
      `wss://backend.snowtex.org/ws/chat/${token}/`
    );
    setSocket(newSocket);
    newSocket.onmessage = (event) => {
      // console.log("event-datafromChat", JSON.parse(event.data));
      setIndex(index + 1);
    };
    // newSocket.onmessage = (event) => {
    //   setLastMessege((prevMessages) => [...prevMessages, JSON.parse(event.data)]);
    //   // alert('open')
    // };
    return () => {
      newSocket.close();
    };
  }, [index]);

  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState([]);

  const [render, setRender] = useState("home");
  const [activeFnd, setActiveFnd] = useState(null);
  const [chatLoading, setChatLoading] = useState(true);
  const [chatList, setChatList] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);

  const getChats = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/?offset=${pageNumber}&limit=100`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const response = res.data.data.results;
        const newRes = response.map((person) => {
          return {
            ...person,
            group_name:
              person.group_name === null
                ? person?.receiver?.first_name +
                  " " +
                  person?.receiver?.last_name
                : person.group_name,
          };
        });
        console.log("newRes", newRes);
        setChatList(newRes);
        setChatLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };

  useEffect(() => {
    getChats();
  }, [pageNumber, index, render]);

  const getMoreData = () => {
    setPageNumber(pageNumber + 1);
    console.log("called get more data");
  };
  // const chatList = [
  //   {
  //     id: 1,
  //     name: "Robert Alexa",
  //     lastText: "How Are You",
  //     type: "friend",
  //     img: active,
  //   },
  //   {
  //     id: 2,
  //     name: "Robin Diaz",
  //     lastText: "Are You ok?",
  //     type: "friend",
  //     img: active2,
  //   },
  //   {
  //     id: 3,
  //     name: "David Via",
  //     lastText: "Hey",
  //     type: "friend",
  //     img: active3,
  //   },
  //   {
  //     id: 4,
  //     name: "Friend & Family",
  //     lastText: "ok",
  //     type: "group",
  //     img: group,
  //   },
  //   {
  //     id: 5,
  //     name: "Robert Alexa",
  //     lastText: "How Are You",
  //     type: "friend",
  //     img: active,
  //   },
  // ];
  console.log(activeFnd);

  // soket

  return (
    <div className="chat_parent ">
      {/* sideBar */}
      <div className="side_bar">
        {render === "home" && (
          <SideBar
            getMoreData={getMoreData}
            chatList={chatList}
            chatLoading={chatLoading}
            setRender={setRender}
            activeFnd={activeFnd}
            setActiveFnd={setActiveFnd}
            render={render}
            
          />
        )}
        {render === "profile" && <Profile setRender={setRender} />}
        {render === "edit-profile" && <EditProfile setRender={setRender} />}
        {render === "contact" && (
          <NewChat
            setActiveFnd={setActiveFnd}
            chatList={chatList}
            setRender={setRender}
            socket={socket}
            setIndex={setIndex}
          />
        )}
        {render === "account" && <Accounts setRender={setRender} />}
        {render === "privacy-policy" && <Settings setRender={setRender} />}
        {render === "notification" && <Notification setRender={setRender} />}
        {render === "help" && <Help setRender={setRender} />}
        {render === "create-group" && (
          <AddNewPerticipaints setRender={setRender} setIndex={setIndex} socket={socket} />
        )}
        {render === "friend-profile" && (
          <FriendProfile setRender={setRender} activeFnd={activeFnd} />
        )}
        {/* <AddNewPerticipaints/> */}
      </div>
      <div className="chats">
        {activeFnd === null && <NoChat />}
        {activeFnd !== null && activeFnd.type === 0 && (
          <Chats
            index={index}
            setIndex={setIndex}
            setRender={setRender}
            socket={socket}
            activeFnd={activeFnd}
          />
        )}
        {activeFnd !== null && activeFnd.type === 1 && (
          <GroupChat
            index={index}
            setIndex={setIndex}
            socket={socket}
            setRender={setRender}
            activeFnd={activeFnd}
          />
        )}
      </div>
    </div>
  );
};

export default ChatMainContainer;
