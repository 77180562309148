import { IconButton } from '@mui/material'
import {  Question, ShieldWarning } from 'phosphor-react'
import React from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

const Help = ({setRender}) => {
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={()=>setRender('privacy-policy')}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "22px" }}>Help</h3>
        </div>
      </div>
      <div className="settings_details">
        <hr style={{color:'white'}} />
        <div className="settings_div">
          <div className="settings_icon">
          <Question color="white" size={22}  />
          </div>
          <h5>Help Center</h5>
        </div>
        <hr />
        <div className="settings_div">
          <div className="settings_icon">
            <ContactsOutlinedIcon color="white" size={22} weight="light" />
          </div>
          <h5>Contact Us</h5>
        </div>
        <hr />
        <div className="settings_div">
          <div className="settings_icon">
            <ShieldWarning color="white" size={22}  />
          </div>
          <h5>Privacy Policy</h5>
        </div>
        <hr />
      </div>
    </div>
  )
}

export default Help