import React from "react";

const Faq = () => {
  return (
    <div className='me-3'>
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M16.3745 14.498C17.3904 13.0757 18 11.3824 18 9.48601C18 4.81271 14.2072 0.952148 9.46614 0.952148C4.7251 0.952148 1 4.81271 1 9.55374C1 14.2948 4.79283 18.0199 9.46614 18.0199C11.1594 18.0199 12.7849 17.478 14.1394 16.5976L17.3227 18.3585L16.3745 14.498Z"
        stroke="#1F5DA0"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M6.82474 9.6892H4.99606V11.4502H4.04785V7.18323H7.09566V7.92825H5.06379V9.01191H6.82474V9.6892Z"
        fill="#1F5DA0"
      />
      <path
        d="M9.60152 10.5697H7.90829L7.56965 11.4502H6.55371L8.24694 7.18323H9.12742L10.8884 11.4502H9.87244L9.60152 10.5697ZM8.17921 9.89239H9.3306L8.72104 8.26689L8.17921 9.89239Z"
        fill="#1F5DA0"
      />
      <path
        d="M15.0875 9.41829C15.0875 9.82467 15.0197 10.1633 14.8843 10.4342C14.7488 10.7051 14.5456 10.9761 14.2747 11.1115L15.0197 11.6534L14.4102 12.1275L13.462 11.4502C13.3265 11.4502 13.2588 11.4502 13.1233 11.4502C12.7169 11.4502 12.3783 11.3824 12.1074 11.1792C11.8365 10.9761 11.5656 10.7729 11.4301 10.4342C11.2946 10.0956 11.1592 9.75694 11.1592 9.35056V9.14738C11.1592 8.741 11.2269 8.33463 11.4301 8.06371C11.6333 7.7928 11.8365 7.52188 12.1074 7.31869C12.3783 7.1155 12.7847 7.04778 13.1233 7.04778C13.5297 7.04778 13.8683 7.1155 14.1393 7.31869C14.4102 7.52188 14.6811 7.72507 14.8165 8.06371C14.952 8.40236 15.0875 8.741 15.0875 9.14738V9.41829ZM14.1393 9.21511C14.1393 8.741 14.0715 8.40236 13.8683 8.19917C13.6652 7.99598 13.462 7.86052 13.1233 7.86052C12.7847 7.86052 12.5815 7.99598 12.3783 8.19917C12.1751 8.40236 12.1074 8.741 12.1074 9.21511V9.41829C12.1074 9.82467 12.1751 10.1633 12.3783 10.4342C12.5815 10.7051 12.7847 10.7729 13.1233 10.7729C13.462 10.7729 13.6652 10.6374 13.8683 10.4342C14.0715 10.231 14.1393 9.8924 14.1393 9.41829V9.21511Z"
        fill="#1F5DA0"
      />
    </svg>
    </div>
  );
};

export default Faq;
