import {
  getListItemSecondaryActionClassesUtilityClass,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { Users } from "phosphor-react";
import active from "../../../../assets/logo/active.svg";
import active2 from "../../../../assets/logo/active2.svg";
import active3 from "../../../../assets/logo/activeFe.svg";
import "../SideBar.css";
import { BASE_URL } from "../../../Const/Url";
import axios from "axios";
import { useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { showToast } from "../../../../utils/ToastHelper";
import useWebSocket from "react-use-websocket";

const NewChat = ({ setRender, chatList, setActiveFnd, socket, setIndex }) => {
  const getUser = JSON.parse(localStorage.getItem("userData"));
  console.log("contactuser", getUser);
  const [query, setQuery] = useState("");
  const [contact, setContact] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
 
  const getContact = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/contact/`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data);
        const newData = res.data.data;
        setContact(newData.filter((data) => data?.mobile !== getUser?.mobile));
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };
  useEffect(() => {
    getContact();
  }, []);
  console.log("contact", contact);
  const keys = ["first_name", "last_name"];
  const getData = contact?.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );
  const SingleContact = (item) => {
    const contactName = item?.id;
    const newChatList = chatList.filter((chat) => chat.convo_type === 0);
    const getActiveContact = newChatList.filter(
      (chat) =>
        chat?.receiver?.id ===
        contactName
    );
    console.log("getActiveContactItem", item);
    if (getActiveContact?.length > 0) {
      setActiveFnd({
        id: getActiveContact[0]?.id,
        type: 0,
      });
      setRender("home");
    } else {
      console.log('soketEvent',socket)
      socket.send(
        JSON.stringify({
          event: "create_convo",
          data: {
            group_name: null,
            admin: [],
            is_active: true,
            convo_type: 0,
            group_image: null,
            users: [item?.id],
          },
        })
      );
      // alert(`clicked-${item.id}`)
      setActiveFnd(null);
      setRender("home");
      setIndex((prev) => prev + 1);
     
    }
  };

  if (isLoading) {
    return (
      <div className="loader_main ">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <BeatLoader color="#fff" />
        </div>
      </div>
    );
  }
  const timeFunction = (time) => {
    const date = new Date(time);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedTime = formatter.format(date);
    return formattedTime;
  };

  return (
    <div className="profile_main new_perticipate">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "22px" }}>New Chat</h3>
        </div>
      </div>
      <div
        className="sidebar_search "
        style={{ background: "white", padding: " 14px" }}
      >
        <div className="search">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search or New Chat"
            value={query}
            onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}
          />
        </div>
      </div>
      <hr style={{ marginTop: "-3px" }} />
      <div
        className="settings_profile"
        style={{ cursor: "pointer" }}
        onClick={() => setRender("create-group")}
      >
        <div className="settings_icon">
          <Users color="white" size={38} weight="light" />
        </div>
        <h2>New Group</h2>
      </div>
      <p
        style={{
          color: "#1F5DA0",
          marginLeft: "22px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        Contact List
      </p>
      <div className="sidebar_chats new_perticipate_users">
        {getData ? (
          getData?.map((c) => (
            <div
              onClick={() => SingleContact(c)}
              className="single_chat "
              style={{ padding: "10px 16px" }}
            >
              <div className="d-flex align-items-center">
                <div className="chat_image">
                  <img
                    width={55}
                    height={55}
                    style={{ borderRadius: "50%" }}
                    src={c?.image_url}
                    alt=""
                  />

                  {c?.is_online === true && <div></div>}
                </div>
                <div className="ms-3 d-flex flex-column justify-content-center ">
                  <h5 className="chat_name">
                    {c?.first_name + " " + c?.last_name}{" "}
                  </h5>
                  <span className="chat_text">
                    {c.is_online === true && "Online"}
                    {c?.is_online === false &&
                      c?.last_seen === null &&
                      "Last seen today"}
                    {c?.is_online === false &&
                      c?.last_seen !== null &&
                      `Last Seen ${timeFunction(c?.last_seen)}`}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <p className="my-3 text-center">No Contact Available</p>
          </>
        )}

        {/* <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active2} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">+546964802</h5>
              <span className="chat_text ">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active3} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text ">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active2} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">+546964802</h5>
              <span className="chat_text ">Last seen within a month</span>
            </div>
          </div>
        </div>
        <div className="single_chat " style={{ padding: "10px 16px" }}>
          <div className="d-flex align-items-center">
            <img src={active3} alt="" />
            <div className="ms-3 d-flex flex-column justify-content-center ">
              <h5 className="chat_name">Robert Alexa</h5>
              <span className="chat_text ">Last seen within a month</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default NewChat;
