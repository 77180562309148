import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import avaterImg from "../../../assets/logo/avater.svg";
import docImg from "../../../assets/img/doc.png";
import "./Chatroom.css";
import {
  Check,
  Checks,
  ClockCounterClockwise,
  Files,
  ImageSquare,
  Share,
  User,
} from "phosphor-react";
import CancelIcon from "@mui/icons-material/Cancel";

import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import SpeedDial from "@mui/material/SpeedDial";
import ReplyIcon from "@mui/icons-material/Reply";
import reply from "../../../assets/logo/reply.svg";
import word from "../../../assets/logo/word 1.svg";
import excl from "../../../assets/logo/excel 1.svg";
import vedio from "../../../assets/logo/vedio.svg";
import music from "../../../assets/logo/music_note.svg";
import InfiniteScroll from "react-infinite-scroll-component";

import { styled } from "@mui/material/styles";
import img2 from "../../../assets/logo/image.svg";
import NoChat from "./NoChat";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import { showToast } from "../../../utils/ToastHelper";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import { Modal } from "react-bootstrap";
import { saveAs } from "file-saver";

const GroupChat = ({ activeFnd, setRender, socket, index, setIndex }) => {
  const [currImg, setCurrImg] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setCurrImg(null);
  };
  const handleShow = () => setShow(true);
  const handleDownload = () => {
    // const fileName = currImg?.split("/").pop();
    // const aTag = document.createElement("a");
    // aTag.href = currImg;
    // aTag.setAttribute("download", fileName);
    // document.body.appendChild(aTag);
    // aTag.click();
    // aTag.remove();
    saveAs(currImg, "image.jpg");
  };
  const [attach, setAttach] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const containerRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [newData, setNewData] = useState([]);
  const [liveChat, setLiveChat] = useState([]);
  const [sendExcl, setSendExcl] = useState(null);
  const [sendWord, setSendWord] = useState(null);
  const [sendVedio, setSendVedio] = useState(null);
  const [sendAudio, setSendAudio] = useState(null);
  const [sendFile, setSendFile] = useState(null);

  const onEmojiClick = (emojiData, event) => {
    setInputStr(
      (prevInput) => prevInput + String.fromCodePoint(`0x${emojiData.unified}`)
    );
    console.log(emojiData);

    // if (emojiData.hasOwnProperty(emojiData.emoji) === true) {
    //   alert("is has");
    // } else if (emojiData.hasOwnProperty(emojiData.emoji) === false) {
    //   alert("dosent have");
    // }

    setShowPicker(false);
  };
  const previousItem = localStorage.getItem("previousItem");

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    setSendFile(event.target.files[0]);
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    event.target.value = "";
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [ofset, setOfset] = useState(0);
  const [chatCount, setChatCount] = useState(0);

  useEffect(() => {
    setOfset(0);
  }, [activeFnd.id]);
  const cUser = JSON.parse(localStorage.getItem("userData"));
  const getLiveChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/messages/?limit=30&offset=${ofset}`;
    axios
      .get(url)
      .then((res) => {
        // console.log('Live_chat2',res.data.data.results);
        const data = res?.data?.data?.results;
        const newData = data.reverse();
        let lastIdOneIndex = newData.length - 1;
        for (let i = newData.length - 1; i >= 0; i--) {
          if (newData[i].sender.id !== cUser.id) {
            lastIdOneIndex = i;
            break;
          }
        }
        let changedArray = newData.slice(0, lastIdOneIndex + 1);
        const changeStatus = changedArray.map((obj) => {
          return {
            ...obj,
            status: 2,
          };
        });
        let unchangedArray = newData.slice(lastIdOneIndex + 1);
        setLiveChat([...changeStatus, ...unchangedArray]);
        console.log(res.data.data.count);
        setChatCount(res.data.data.count);
        if (newData[newData?.length - 1]?.sender?.id !== cUser?.id) {
          socket.onmessage({
            event: "message_open",
            data: {
              conversation: activeFnd.id,
              message: newData[newData?.length - 1]?.id,
            },
          });
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };

  const getLiveChat2 = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/messages/?limit=30&offset=${ofset}`;
    axios
      .get(url)
      .then((res) => {
        // console.log('Live_chat2',res.data.data.results);
        const data = res?.data?.data?.results;
        const newData = data;

        // // setLiveChat([...liveChat, ...newData]);
        // // const updated = [...liveChat,...newData]
        setNewData(newData.reverse());
        console.log(res.data.data.count);
        setChatCount(res.data.data.count);
        console.log("newData", newData);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [chat, setChat] = useState({});
  const [userData, setUserData] = useState({});
  const userName = userData?.first_name + " " + userData?.last_name;
  console.log(userName, "userName");
  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setUserData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  }, []);

  const getSingleChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data);
        setChat(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };
  useEffect(() => {
    if (ofset === 0) {
      setLiveChat([]);
      getLiveChat();
    } else {
      getLiveChat2();
      setLiveChat([...newData, ...liveChat]);
      setNewData([]);
    }
  }, [messages, activeFnd.id, ofset, activeFnd,index]);

  useEffect(() => {
    getSingleChat();
  }, [activeFnd]);
  useEffect(() => {
    containerRef?.current?.scrollIntoView();
  }, [messages, getLiveChat]);

  const handleClick = (str, id) => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    if (
      inputStr !== "" ||
      sendFile?.length > 0 ||
      sendVedio?.length > 0 ||
      sendAudio?.length > 0 ||
      sendWord?.length > 0 ||
      sendExcl?.length > 0
    ) {
      const data1 = new FormData();
      if (sendFile?.length > 0) {
        Object.values(sendFile).map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 0);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: inputStr,
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendVedio?.length > 0) {
        Object.values(sendVedio)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 1);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendAudio?.length > 0) {
        Object.values(sendAudio)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 2);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendWord?.length > 0) {
        Object.values(sendWord)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 3);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendExcl?.length > 0) {
        Object.values(sendExcl)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 3);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else {
        socket.send(
          JSON.stringify({
            event: "message_send",
            data: {
              conversation: id,
              message: inputStr,
              attachments: [],
              sticker: null,
              message_type: 0,
            },
          })
        );
      }
    } else {
      showToast("error", "Please Type Something!");
    }
    setOfset(0);
    getLiveChat();
    setInputStr("");
    setSendFile("");
    setSendVedio("");
    setSendAudio("");
    setSendExcl("");
    setSendWord("");
  };
  const dataRemove = () => {
    setInputStr("");
    setSendFile("");
    setSendVedio("");
    setSendAudio("");
    setSendExcl("");
    setSendWord("");
  };
  useEffect(() => {
    socket.onmessage = (event) => {
      console.log("event", event);
      const triggerdEvent = event?.event;

      if (triggerdEvent === "message_open") {
        const conversation = event?.data?.conversation;
        const lastTextId = event?.data?.message;
        if (conversation === activeFnd.id) {
          // liveChat.map(chat) update
          setLiveChat((prevState) => {
            return prevState.map((obj) => {
              return {
                ...obj,
                status: 2,
              };
            });
          });
        }
      } else if (triggerdEvent === "message_received") {
        const conversation = event?.data?.conversation;
        const lastTextId = event?.data?.message;
        if (conversation === activeFnd.id) {
        }
        // alert('message_received')
      } else {
        setIndex(index + 1);
        setMessages(event.data);
      }
    };
  }, [activeFnd.id]);
  // useEffect(() => {
  //   socket.onmessage = (event) => {
  //     // showToast("success", "Text Sended");
  //     setIndex(index + 1);
  //     setMessages((prevMessages) => [...prevMessages, JSON.parse(event.data)]);
  //   };
  // }, []);

  const timeFunction = (time) => {
    const date = new Date(time);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedTime = formatter.format(date);
    return formattedTime;
  };
  function handleScroll(event) {
    if (liveChat?.length === chatCount) {
      return;
    }
    if (liveChat?.length === chatCount) {
      event.target.removeEventListener("scroll", handleScroll);
      return;
    } else if (liveChat?.length === chatCount && event.target.scrollTop === 0) {
      event.target.removeEventListener("scroll", handleScroll);
      return;
    } else if (liveChat?.length <= chatCount) {
      if (event.target.scrollTop === 0) {
        setOfset(ofset + 30);
      }
    }
  }
  console.log("groupChatCount", chatCount);
  const handleSend = (e) => {
    if (
      inputStr ||
      sendAudio ||
      sendExcl ||
      sendVedio ||
      sendWord ||
      sendFile
    ) {
      if (e.keyCode == 13) {
        handleClick(inputStr, chat.id);
      }
    }
  };

  const gDate = (d) => {
    return d?.created_at?.split("T")[0];
  };
  if (isLoading) {
    return <NoChat />;
  }
  console.log('groupChat',liveChat)
  return (
    <div>
      {" "}
      <div className="chat-container">
        <div className="sidebar_hedar hedaer_shadow sidebar_header_right2">
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer", padding: "16px 0px" }}
          >
            <Avatar
              onClick={() => setRender("friend-profile")}
              src={chat?.group_image_url}
            />{" "}
            <div className="ms-2  left_user ">
              <span className="ms-2 my-auto " style={{ fontSize: "19px" }}>
                {chat?.group_name}
              </span>
              {/* <p className="chat_text pt-2" style={{ marginLeft: "7px" ,opacity:'0'}}>
                Lia,Robert
              </p> */}
            </div>
          </div>
          <div className="sidebar_header_right">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open2 ? "long-menu" : undefined}
              aria-expanded={open2 ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick2}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              className="pop_over3"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                style: {
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  width: "22ch",
                  marginRight: "10ch",
                  top: "100px",
                  padding: "10px ",
                },
              }}
            >
              <MenuItem className="privacy my-2" onClick={handleClose2}>
                {" "}
                <ImageSquare
                  color="black"
                  size={28}
                  weight="light"
                  className="me-3"
                />
                Image
              </MenuItem>
              <MenuItem className="privacy my-2" onClick={handleClose2}>
                {" "}
                <Files
                  color="black"
                  size={28}
                  weight="light"
                  className="me-3"
                />
                Documents
              </MenuItem>
              <MenuItem className="privacy my-2" onClick={handleClose2}>
                {" "}
                <ClockCounterClockwise
                  color="black"
                  size={28}
                  className="me-3"
                />
                History
              </MenuItem>
              <MenuItem
                className="privacy my-2"
                onClick={() => {
                  setRender("friend-profile");
                  handleClose2();
                }}
              >
                {" "}
                <User color="black" size={28} className="me-3" />
                View Profile
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="chat-body group mt-2" onScroll={handleScroll}>
          {liveChat?.length > 0 ? (
            liveChat.map((chat) => (
              <>
                {/* {gDate(liveChat[index - 1]) !== gDate(chat) && (
                  <div className="d-flex mb-2 justify-content-center">
                    <span style={{ textAlign: "center", fontSize: "12px" }}>
                      {gDate(chat)}
                    </span>
                  </div>
                )} */}
                <div
                  className={`messege ${
                    chat?.sender?.first_name + " " + chat?.sender?.last_name ===
                    userName
                      ? "my_messege2nd"
                      : "fnd_messege2nd"
                  } `}
                >
                  {chat?.message?.length === 0 ? (
                    <>
                      {" "}
                      {chat?.sender?.first_name +
                        " " +
                        chat?.sender?.last_name ===
                      userName ? (
                        <>
                          {chat?.attachments?.length > 0 && (
                            <>
                              <p className="image_div_last">
                                {chat?.attachments[0]?.doc_type === 0 && (
                                  <>
                                    <img
                                      onClick={() => {
                                        setCurrImg(
                                          chat?.attachments[0]?.doc_url
                                        );
                                        handleShow();
                                      }}
                                      style={{
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                      }}
                                      width={200}
                                      height={200}
                                      src={chat?.attachments[0]?.doc_url}
                                      alt=""
                                    />
                                  </>
                                )}
                                {chat?.attachments[0]?.doc_type === 1 && (
                                  <video
                                    controls
                                    src={chat?.attachments[0]?.doc_url}
                                    style={{ borderRadius: "10px" }}
                                    width={200}
                                    height={200}
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 2 && (
                                  <audio
                                    controls
                                    src={chat?.attachments[0]?.doc_url}
                                    style={{ borderRadius: "10px" }}
                                    width={150}
                                    height={50}
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 3 && (
                                  <img
                                    onClick={() => {
                                      const fileName =
                                        chat?.attachments[0]?.doc_url
                                          ?.split("/")
                                          .pop();
                                      const aTag = document.createElement("a");
                                      aTag.href = chat?.attachments[0]?.doc_url;
                                      aTag.setAttribute("download", fileName);
                                      document.body.appendChild(aTag);
                                      aTag.click();
                                      aTag.remove();
                                    }}
                                    width={60}
                                    src={docImg}
                                    alt=""
                                  />
                                )}

                                <br />
                                <span className="image_span_last">
                                  {timeFunction(chat?.created_at)}
                                  {chat?.sender?.first_name +
                                    " " +
                                    chat?.sender?.last_name ===
                                    userName && (
                                    <>
                                      {chat?.status === 0 && (
                                        <Checks
                                          size={17}
                                          weight="light"
                                          color="#000"
                                          style={{ marginBottom: "3px" }}
                                        />
                                      )}
                                      {chat?.status === 1 && (
                                        <Check
                                          size={17}
                                          weight="light"
                                          color="#000"
                                          style={{ marginBottom: "3px" }}
                                        />
                                      )}
                                      {chat?.status === 2 && (
                                        <Checks
                                          size={17}
                                          weight="light"
                                          color="blue"
                                          style={{ marginBottom: "3px" }}
                                        />
                                      )}{" "}
                                    </>
                                  )}
                                </span>
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p className="image_div_last">
                            {chat?.attachments[0]?.doc_type === 0 && (
                              <>
                                {chat?.sender?.first_name +
                                  " " +
                                  chat?.sender?.last_name !==
                                  userName && (
                                  <span className="name mb-2">
                                    {chat?.sender?.first_name}
                                  </span>
                                )}

                                <img
                                  onClick={() => {
                                    setCurrImg(chat?.attachments[0]?.doc_url);
                                    handleShow();
                                  }}
                                  style={{
                                    borderRadius: "10px",
                                    objectFit: "cover",
                                  }}
                                  width={200}
                                  height={200}
                                  src={chat?.attachments[0]?.doc_url}
                                  alt=""
                                  className="pt-1"
                                />
                              </>
                            )}
                            {chat?.attachments[0]?.doc_type === 1 && (
                              <>
                                {chat?.sender?.first_name +
                                  " " +
                                  chat?.sender?.last_name !==
                                  userName && (
                                  <span className="name mb-2">
                                    {chat?.sender?.first_name}
                                  </span>
                                )}
                                <video
                                  controls
                                  src={chat?.attachments[0]?.doc_url}
                                  style={{ borderRadius: "10px" }}
                                  width={200}
                                  height={200}
                                  className="pt-1"
                                />
                              </>
                            )}
                            {chat?.attachments[0]?.doc_type === 2 && (
                              <>
                                {chat?.sender?.first_name +
                                  " " +
                                  chat?.sender?.last_name !==
                                  userName && (
                                  <span className="name mb-2">
                                    {chat?.sender?.first_name}
                                  </span>
                                )}
                                <audio
                                  className="pt-1"
                                  controls
                                  src={chat?.attachments[0]?.doc_url}
                                  style={{ borderRadius: "10px" }}
                                  width={150}
                                  height={50}
                                />
                              </>
                            )}
                            {chat?.attachments[0]?.doc_type === 3 && (
                              <>
                                {chat?.sender?.first_name +
                                  " " +
                                  chat?.sender?.last_name !==
                                  userName && (
                                  <span className="name mb-2">
                                    {chat?.sender?.first_name}
                                  </span>
                                )}
                                {chat?.attachments[0]?.doc_type === 3 && (
                                  <img
                                    onClick={() => {
                                      const fileName =
                                        chat?.attachments[0]?.doc_url
                                          ?.split("/")
                                          .pop();
                                      const aTag = document.createElement("a");
                                      aTag.href = chat?.attachments[0]?.doc_url;
                                      aTag.setAttribute("download", fileName);
                                      document.body.appendChild(aTag);
                                      aTag.click();
                                      aTag.remove();
                                    }}
                                    width={60}
                                    src={docImg}
                                    className="pt-1"
                                    alt=""
                                  />
                                )}
                              </>
                            )}
                            <br />
                            <span className="image_span_last ">
                              {timeFunction(chat?.created_at)}{" "}
                              {chat?.sender?.first_name +
                                " " +
                                chat?.sender?.last_name ===
                                userName && (
                                <>
                                  {chat?.status === 0 && (
                                    <Checks
                                      size={17}
                                      weight="light"
                                      color="#000"
                                      style={{
                                        marginBottom: "3px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  )}
                                  {chat?.status === 1 && (
                                    <Check
                                      size={17}
                                      weight="light"
                                      color="#000"
                                      style={{
                                        marginBottom: "3px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  )}
                                  {chat?.status === 2 && (
                                    <Check
                                      size={17}
                                      weight="light"
                                      color="#A7A7A7"
                                      style={{
                                        marginBottom: "3px",
                                        marginLeft: "5px",
                                      }}
                                    />
                                  )}{" "}
                                </>
                              )}
                            </span>
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <p>
                      {chat?.sender?.first_name +
                        " " +
                        chat?.sender?.last_name !==
                        userName && (
                        <span className="name mb-3">
                          {chat?.sender?.first_name}
                        </span>
                      )}
                      {chat?.message} <span></span>
                      <span className="ms-2">
                        {timeFunction(chat?.created_at)}{" "}
                        {chat?.sender?.first_name +
                          " " +
                          chat?.sender?.last_name ===
                          userName && (
                          <>
                            {chat?.status === 0 && (
                              <Checks
                                size={17}
                                weight="light"
                                color="#000"
                                style={{
                                  marginBottom: "3px",
                                  marginLeft: "3px",
                                }}
                              />
                            )}
                            {chat?.status === 1 && (
                              <Check
                                size={17}
                                weight="light"
                                color="#000"
                                style={{
                                  marginBottom: "3px",
                                  marginLeft: "3px",
                                }}
                              />
                            )}
                            {chat?.status === 2 && (
                              <Checks
                                size={17}
                                weight="light"
                                color="blue"
                                style={{
                                  marginBottom: "3px",
                                  marginLeft: "3px",
                                }}
                              />
                            )}{" "}
                          </>
                        )}
                      </span>
                    </p>
                  )}

                  {/* {chat?.message?.length === 0 ? (
                  <>
                    {" "}
                    {chat?.sender?.first_name +
                      " " +
                      chat?.sender?.last_name ===
                    userName ? (
                      <>
                        <div
                          className="img_reply rounded-circle my-auto me-1"
                          style={{
                            background: "#AEAEAE",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <img style={{ rotate: "0" }} src={reply} alt="" />
                        </div>
                        <p>
                          {chat?.sender?.first_name +
                            " " +
                            chat?.sender?.last_name !==
                            userName && (
                            <span className="name mb-2">
                              {chat?.sender?.first_name}
                            </span>
                          )}
                          <img
                            style={{ borderRadius: "10px",marginTop:'5px' }}
                            width={150}
                            height={150}
                            src={chat?.attachments[0]?.doc_url}
                            alt=""
                          />
                          <br />
                          <span className="img_span">
                            {timeFunction(chat?.created_at)}
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          {chat?.sender?.first_name +
                            " " +
                            chat?.sender?.last_name !==
                            userName && (
                            <span className="name mb-2">
                              {chat?.sender?.first_name}
                            </span>
                          )}
                          <img
                            style={{ borderRadius: "10px" ,marginTop:'5px'}}
                            width={150}
                            height={150}
                            src={chat?.attachments[0]?.doc_url}
                            alt=""
                          />
                          <br />
                          <span className="img_span">
                            {timeFunction(chat?.created_at)}
                          </span>
                        </p>
                        <div
                          className="img_reply rounded-circle my-auto ms-1"
                          style={{
                            background: "#AEAEAE",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <img src={reply} alt="" />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <p>
                    {chat?.sender?.first_name + " " + chat?.sender?.last_name !==
                    userName && (
                    <span className="name mb-2">{chat?.sender?.first_name}</span>
                  )}
                    {chat?.message}{" "}
                    <span>{timeFunction(chat?.created_at)}</span>
                  </p>
                )} */}
                </div>
              </>
            ))
          ) : (
            <>
              <p
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{ color: "white" }}
              >
                No chat{" "}
              </p>
            </>
          )}

          {/* <div className="messege my_messege">
            <p>
              Hi, How are you? <span>7:14 PM</span>
            </p>
          </div>
          <div className="messege my_messege2nd">
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.{" "}
              <span>7:14 PM</span>
            </p>
          </div>

          <div className="messege fnd_messege">
            <p>
              <span className="name">Lia</span>
              <img src={img2} alt="" />
              <br />
              <span className="img_span">7:14 PM</span>
            </p>
            <div
              className="img_reply rounded-circle my-auto ms-1"
              style={{ background: "#AEAEAE", width: "30px", height: "30px" }}
            >
              <img src={reply} alt="" />
            </div>
          </div>

          <div className="messege my_messege2nd">
            <p>
              How can i help you? <span>7:14 PM</span>
            </p>
          </div>
          <div className="messege my_messege2nd">
            <p>
              How can i help you? <span>7:14 PM</span>
            </p>
          </div>
          <div className="messege my_messege2nd">
            <p>
              How can i help you? <span>7:14 PM</span>
            </p>
          </div>
          <div className="messege fnd_messege">
            <p>
              <span className="name">Lia</span>
              Hi, I'm Fine <span>7:14 PM</span>
            </p>
          </div>
          <div className="messege my_messege2nd">
            <p>
              How can i help you? <span>7:14 PM</span>
            </p>
          </div> */}
          <div ref={containerRef}></div>
        </div>
        {attach === true && (
          <div className="attach_container">
            <div
              className="excl"
              style={{ background: "#31A01F" }}
              // onClick={() => setAttach(false)}
            >
              <img src={excl} alt="" />
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  setSendExcl(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="word"
              style={{ background: "#4797EE" }}
              // onClick={() => setAttach(false)}
            >
              <img src={word} alt="" />
              <input
                type="file"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(e) => {
                  setSendWord(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="vedio"
              style={{ background: "#A410C9" }}
              // onClick={() => setAttach(false)}
            >
              <img src={vedio} alt="" />
              <input
                type="file"
                accept="video/*"
                onChange={(e) => {
                  setSendVedio(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="music"
              style={{ background: "#FF7F23" }}
              // onClick={() => setAttach(false)}
            >
              <img src={music} alt="" />
              <input
                type="file"
                accept="audio/*"
                onChange={(e) => {
                  setSendAudio(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
          </div>
        )}
        <footer className="py-3 px-3">
          <div className="d-flex">
            <IconButton
              className="emoji_btn "
              onClick={() => setShowPicker(!showPicker)}
            >
              <EmojiEmotionsOutlinedIcon />
            </IconButton>
            {showPicker && (
              <EmojiPicker
                pickerStyle={{ position: "absolute", top: "60px" }}
                onEmojiClick={onEmojiClick}
              />
            )}
            <IconButton
              className="emoji_btn ms-2"
              onClick={() => setAttach(!attach)}
            >
              <AttachFileOutlinedIcon />
            </IconButton>

            {/* <StyledSpeedDial
          ariaLabel="SpeedDial playground example"
          icon={
              <AttachFileOutlinedIcon />
          }
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </StyledSpeedDial> */}

            <div className="input_footer">
              {sendFile ? (
                <>
                  <div className="imageDiv">Image Selected</div>
                </>
              ) : sendVedio ? (
                <>
                  <div className="imageDiv">Vedio Selected</div>
                </>
              ) : sendAudio ? (
                <>
                  <div className="imageDiv">Audio Selected</div>
                </>
              ) : sendExcl || sendWord ? (
                <>
                  <div className="imageDiv">Document Selected</div>
                </>
              ) : (
                <input
                  type="text"
                  onKeyDown={handleSend}
                  onChange={(e) => setInputStr(e.target.value)}
                  placeholder="Type a Message"
                  value={inputStr}
                />
              )}

              <div className="cam">
                {sendExcl || sendAudio || sendFile || sendVedio || sendWord ? (
                  <CancelIcon onClick={dataRemove} />
                ) : (
                  <CameraAltOutlinedIcon />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setSendFile(e.target.files)}
                  name="images"
                  multiple
                />
              </div>
            </div>
            <IconButton
              onClick={() => handleClick(inputStr, chat.id)}
              className="send_btn ms-2"
            >
              <SendOutlinedIcon />
            </IconButton>
          </div>
        </footer>
        <>
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={currImg}
                width={350}
                height={300}
                alt=""
                style={{ objectFit: "cover", borderRadius: "5px" }}
              />
              <div className="my-2 download">
                <button
                  onClick={handleDownload}
                  className="px-3 py-2 "
                  style={{
                    border: "1px solid blue",
                    outline: "none",
                    borderRadius: "5px",
                    color: "blue",
                  }}
                >
                  Download
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
};

export default GroupChat;
